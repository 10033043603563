<template>
     <!-- Main content -->
    <section class="content-wrapper">
        <section class="content-header">
            <h1>Countries, States And Cities</h1>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#"><i class="iconsmind-Library"></i></a></li>
                <li class="breadcrumb-item"><router-link :to="{path: '/'}">Home</router-link></li>
                <li class="breadcrumb-item active">Country</li>
            </ol>
        </section>
		<div class="row">
            <div class="col-12">
                <div class="row">
                    
                </div>
                <div class="row">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 mt-30">
                        <div class="box">
                            <div class="box-header with-border">
                                <div class="">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="box-title">Countries</h5>
                                        </div>
                                        <div class="media-right">
                                            <button class="btn btn-block btn-success btn-sm"  data-toggle="modal" data-target="#add-country">Add</button>
                                            <NewCountry/>
                                        </div>
                                    </div>
                                    <div v-if="isRoleAllowedDelete">
                                        <div>
                                            <h6>Check All</h6>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4">
                                                <input @change="onPickupCheckAllChange('country', 'checkbox_country_pickup_all')" type="checkbox" id="checkbox_country_pickup_all" class="filled-in"/>
                                                <label for="checkbox_country_pickup_all">Pickup</label>
                                            </div>
                                            <div>
                                                <input @change="onDeliveryCheckAllChange('country', 'checkbox_country_delivery_all')" type="checkbox" id="checkbox_country_delivery_all" class="filled-in"/>
                                                <label for="checkbox_country_delivery_all">Delivery</label>
                                            </div>
                                        </div>
                                    </div><br>
                                    <div>
                                        <input v-model="countrySearchQuery" class="form-control" type="text" placeholder="search"/>
                                    </div><br>
                                </div>
                            </div>
                            <div class="box-body p-0">
                                <div class="media-list media-list-hover media-list-divided">
                                    <div  v-for="country in countryList" :key="country.id"  class="entry" href="#" @click="showStateForCountry(country.id, country.name)">
                                        <div class="d-flex justify-content-between pl-20 pr-20">
                                            <p class="title">{{country.name}}</p>
                                            <p class="badge badge-pill badge-secondary">{{ country.state_count }}</p>
                                        </div>
                                        <div class="d-flex justify-content-between pl-20 pr-20">
                                            <div class="d-flex">
                                                <div class="mr-4">
                                                    <input v-if="isRoleAllowedDelete" type="checkbox" :id="`checkbox_pickup_${country.id}`" class="filled-in" @change="onPickupChange(`checkbox_pickup_${country.id}`, country.id)"  :checked="selectedPickupCountries && selectedPickupCountries.includes(country.id)"/>
                                                    <input v-else type="checkbox" class="filled-in"  :checked="selectedPickupCountries && selectedPickupCountries.includes(country.id)" disabled/>
                                                    <!-- <input v-model="selectedPickupCountries" type="checkbox" :id="`checkbox_pickup_${country.id}`" class="filled-in" :value="country.id" :checked="country.pickup==true"/> -->
                                                    <label :for="`checkbox_pickup_${country.id}`">Pickup</label>
                                                </div>
                                                <div>
                                                    <input v-if="isRoleAllowedDelete" type="checkbox" :id="`checkbox_delivery_${country.id}`" class="filled-in" @change="onDeliveryChange(`checkbox_delivery_${country.id}`, country.id)"  :checked="selectedDeliveryCountries && selectedDeliveryCountries.includes(country.id)"/>
                                                    <input v-else type="checkbox" class="filled-in"  :checked="selectedDeliveryCountries && selectedDeliveryCountries.includes(country.id)" disabled/>
                                                    <!-- <input v-model="selectedDeliveryCountries" type="checkbox" :id="`checkbox_delivery_${country.id}`" class="filled-in" :value="country.id" :checked="country.delivery==true"/> -->
                                                    <label :for="`checkbox_delivery_${country.id}`">Delivery</label>
                                                </div>
                                            </div>
                                            <div>
                                                <button v-if="isRoleAllowedDelete"  @click="deleteCountry(country.id)" type="button" class="btn btn-sm btn-danger-outline" data-toggle="tooltip" data-original-title="Delete"><i class="ti-trash" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                        <hr>
                                        <!-- <div class="row">
                                            <div class="col-md-10">
                                                <p>{{country.name}}</p>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="">
                                                    <p class="badge badge-pill badge-secondary">{{ country.state_count }}</p><br>
                                                    <button type="button" class="btn btn-sm btn-danger-outline" data-toggle="tooltip" data-original-title="Delete"><i class="ti-trash" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div>
                                            <p class="title">{{country.name}}</p><br>
                                            <div class="d-flex flex-row demo-checkbox">
                                                <input type="checkbox" :id="`checkbox_pickup_${country.id}`" class="filled-in"/>
                                                <label :for="`checkbox_pickup_${country.id}`">Pickup</label>
                                                <input type="checkbox" :id="`checkbox_delivery_${country.id}`" class="filled-in"/>
                                                <label :for="`checkbox_delivery_${country.id}`">Delivery</label>
                                            </div>
                                        </div>
                                        <div>
                                            <p class="badge badge-pill badge-secondary">{{ country.state_count }}</p><br>
                                            <button type="button" class="btn btn-sm btn-danger-outline" data-toggle="tooltip" data-original-title="Delete"><i class="ti-trash" aria-hidden="true"></i></button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 mt-30">
                        <div class="box">
                            <div class="box-header with-border">
                                <div class="">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="box-title">States In {{selectedCountryName}}</h5>
                                        </div>
                                        <div v-if="selectedCountryID" class="media-right">
                                            <button class="btn btn-block btn-success btn-sm"  data-toggle="modal" data-target="#add-state">Add</button>
                                            <NewState :countryID="selectedCountryID"/>
                                        </div>
                                    </div>
                                    <div v-if="selectedCountryID && isRoleAllowedDelete">
                                        <div>
                                            <h6>Check All</h6>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4">
                                                <input @change="onPickupCheckAllChange('state', 'checkbox_state_pickup_all')" type="checkbox" id="checkbox_state_pickup_all" class="filled-in"/>
                                                <label for="checkbox_state_pickup_all">Pickup</label>
                                            </div>
                                            <div>
                                                <input @change="onDeliveryCheckAllChange('state', 'checkbox_state_delivery_all')" type="checkbox" id="checkbox_state_delivery_all" class="filled-in"/>
                                                <label for="checkbox_state_delivery_all">Delivery</label>
                                            </div>
                                        </div>
                                    </div><br>
                                    <div v-if="selectedCountryID">
                                        <input v-model="stateSearchQuery" class="form-control" type="text" placeholder="search"/>
                                    </div><br>
                                </div>
                            </div>
                            <div class="box-body p-0">
                                <div class="media-list media-list-hover media-list-divided">
                                    <!-- <a v-for="state in stateList" :key="state.id" href="#" class="media media-single" @click="showCityForState(state.id, state.name)">
                                        <span class="title">{{state.name}}</span>
                                        <span class="badge badge-pill badge-secondary">{{ state.city_count }}</span>
                                    </a> -->


                                    <div v-for="state in stateList" :key="state.id" href="#" class="entry" @click="showCityForState(state.id, state.name)">
                                        <div class="d-flex justify-content-between pl-20 pr-20">
                                            <p class="title">{{state.name}}</p>
                                            <p class="badge badge-pill badge-secondary">{{ state.city_count }}</p>
                                        </div>
                                        <div class="d-flex justify-content-between pl-20 pr-20">
                                            <div class="d-flex">
                                                <div class="mr-4">
                                                    <input v-if="isRoleAllowedDelete" type="checkbox" :id="`checkbox_pickup_${state.id}`" class="filled-in" @change="onPickupChange(`checkbox_pickup_${state.id}`, null, state.id)" :checked="selectedPickupStates && selectedPickupStates.includes(state.id)"/>
                                                    <input v-else type="checkbox" class="filled-in"  :checked="selectedPickupStates && selectedPickupStates.includes(state.id)" disabled/>
                                                    <label :for="`checkbox_pickup_${state.id}`">Pickup</label>
                                                </div>
                                                <div>
                                                    <input v-if="isRoleAllowedDelete" type="checkbox" :id="`checkbox_delivery_${state.id}`" class="filled-in" @change="onDeliveryChange(`checkbox_delivery_${state.id}`, null, state.id)" :checked="selectedDeliveryStates && selectedDeliveryStates.includes(state.id)"/>
                                                    <input v-else type="checkbox" class="filled-in"  :checked="selectedDeliveryStates && selectedDeliveryStates.includes(state.id)" disabled/>
                                                    <label :for="`checkbox_delivery_${state.id}`">Delivery</label>
                                                </div>
                                            </div>
                                            <div>
                                                <button v-if="isRoleAllowedDelete" @click="deleteState(state.id)" type="button" class="btn btn-sm btn-danger-outline" data-toggle="tooltip" data-original-title="Delete"><i class="ti-trash" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 mt-30">
                        <div class="box">
                            <div class="box-header with-border">
                                <div class="">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="box-title">Cities In {{ selectedStateName }}, {{ selectedCountryName }}</h5>
                                        </div>
                                        <div v-if="selectedStateID" class="media-right">
                                            <button class="btn btn-block btn-success btn-sm"  data-toggle="modal" data-target="#add-city">Add</button>
                                            <NewCity :stateID="selectedStateID"/>
                                        </div>
                                    </div>
                                    <div v-if="selectedStateID && isRoleAllowedDelete">
                                        <div>
                                            <h6>Check All</h6>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4">
                                                <input @change="onPickupCheckAllChange('city', 'checkbox_city_pickup_all')" type="checkbox" id="checkbox_city_pickup_all" class="filled-in"/>
                                                <label for="checkbox_city_pickup_all">Pickup</label>
                                            </div>
                                            <div>
                                                <input @change="onDeliveryCheckAllChange('city', 'checkbox_city_delivery_all')" type="checkbox" id="checkbox_city_delivery_all" class="filled-in"/>
                                                <label for="checkbox_city_delivery_all">Delivery</label>
                                            </div>
                                        </div>
                                    </div><br>
                                    <div v-if="selectedStateID">
                                        <input v-model="citySearchQuery" class="form-control" type="text" placeholder="search"/>
                                    </div><br>
                                </div>
                            </div>
                            <div class="box-body p-0">
                                <div class="media-list media-list-hover media-list-divided">
                                    <!-- <a v-for="city in cityList" :key="city.id" class="media media-single" href="#">
                                        <span class="title">{{city.name}}</span>
                                    </a> -->

                                    <div v-for="city in cityList" :key="city.id" class="entry">
                                        <div class="d-flex justify-content-between pl-20 pr-20">
                                            <p class="title">{{city.name}}</p>
                                        </div>
                                        <div class="d-flex justify-content-between pl-20 pr-20">
                                            <div class="d-flex">
                                                <div class="mr-4">
                                                    <input v-if="isRoleAllowedDelete" type="checkbox" :id="`checkbox_pickup_${city.id}`" class="filled-in" @change="onPickupChange(`checkbox_pickup_${city.id}`, null, null, city.id)"  :checked="selectedPickupCities && selectedPickupCities.includes(city.id)"/>
                                                    <input v-else type="checkbox" class="filled-in"  :checked="selectedPickupCities && selectedPickupCities.includes(city.id)" disabled/>
                                                    <label :for="`checkbox_pickup_${city.id}`">Pickup</label>
                                                </div>
                                                <div>
                                                    <input v-if="isRoleAllowedDelete" type="checkbox" :id="`checkbox_delivery_${city.id}`" class="filled-in" @change="onDeliveryChange(`checkbox_delivery_${city.id}`, null, null, city.id)"  :checked="selectedDeliveryCities && selectedDeliveryCities.includes(city.id)"/>
                                                    <input v-else type="checkbox" class="filled-in"  :checked="selectedDeliveryCities && selectedDeliveryCities.includes(city.id)" disabled/>
                                                    <label :for="`checkbox_delivery_${city.id}`">Delivery</label>
                                                </div>
                                            </div>
                                            <div>
                                                <button v-if="isRoleAllowedDelete" @click="deleteCity(city.id)" type="button" class="btn btn-sm btn-danger-outline" data-toggle="tooltip" data-original-title="Delete"><i class="ti-trash" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>	
                </div>
            </div>
		</div>
    </section>
</template>

<script>
import NewCountry from '../../components/Region/NewCountry'
import NewState from '../../components/Region/NewState'
import NewCity from '../../components/Region/NewCity'
import { repository } from '../../presenter'
import { mapGetters } from 'vuex';
export default {
    components: {
        NewCountry,
        NewState,
        NewCity,
    },
    data(){
        return{
            publicPath: process.env.BASE_URL,
            countryList: [], selectedPickupCountries:[], selectedDeliveryCountries:[],
            stateList: [], selectedPickupStates:[], selectedDeliveryStates:[],
            cityList: [], selectedPickupCities:[], selectedDeliveryCities:[],
            selectedCountryID: 0,
            selectedCountryName: '',
            selectedStateID: 0,
            selectedStateName: '',
            selectedCityID: 0,
            countrySearchQuery:'',
            stateSearchQuery:'',
            citySearchQuery:'',
        }
    },
    mounted() {
        const listJS = document.createElement('script')
        listJS.setAttribute('src', `${this.publicPath}assets/js/pages/list.js`)
        document.head.appendChild(listJS)

        this.loadCountries(this.countrySearchQuery);
    },
    computed: {
        isRoleAllowedDelete(){
            return repository.adminIsLord()
        },
    },
    watch: {
        countrySearchQuery: function(query){
            this.debounce(function(){
                this.loadCountries(query)
            }, 1000);
        },
        stateSearchQuery: function(query){
            this.debounce(function(){
                this.loadStates(this.selectedCountryID, query)
            }, 1000);
        },
        citySearchQuery: function(query){
            this.debounce(function(){
                this.loadCities(this.selectedStateID, query);
            }, 1000);
        },
    },
    methods:{
        async loadCountries(query=this.countrySearchQuery) {
            const data = {
                query: query,
            }
            const countryResponse = await repository.regions.fetchCountries(data, 'listing_all');
            if (countryResponse.success){
                this.countryList = countryResponse.data;
                if (!data.query){
                    this.selectedPickupCountries = [];
                    this.selectedDeliveryCountries = [];
                    this.countryList.forEach((country)=>{
                        if (country.pickup){
                            this.selectedPickupCountries.push(country.id);
                        }
                        if (country.delivery){
                            this.selectedDeliveryCountries.push(country.id);
                        }
                    })
                }
                this.$nextTick(function(){
                    this.reRenderList()
                })
                return;
            }
        },
        async loadStates(countryID, query=this.stateSearchQuery) {
            const data = {
                query: query,
                countryID: countryID,
            }
            const stateResponse = await repository.regions.fetchStates(data, 'listing_all');
            if (stateResponse.success){
                this.stateList = stateResponse.data;
                if (!data.query){
                    this.selectedPickupStates = [];
                    this.selectedDeliveryStates = [];
                    this.stateList.forEach((state)=>{
                        if (state.pickup){
                            this.selectedPickupStates.push(state.id);
                        }
                        if (state.delivery){
                            this.selectedDeliveryStates.push(state.id);
                        }
                    })
                }
                this.$nextTick(function(){
                    this.reRenderList()
                })
                return;
            }
        },
        async loadCities(stateID, query=this.citySearchQuery) {
            const data = {
                query: query,
                stateID: stateID,
            }
            const cityResponse = await repository.regions.fetchCities(data, 'listing_all');
            if (cityResponse.success){
                this.cityList = cityResponse.data;
                if (!data.query){
                    this.selectedPickupCities = [];
                    this.selectedDeliveryCities = [];
                    this.cityList.forEach((city)=>{
                        if (city.pickup){
                            this.selectedPickupCities.push(city.id);
                        }
                        if (city.delivery){
                            this.selectedDeliveryCities.push(city.id);
                        }
                    })
                }
                this.$nextTick(function(){
                    this.reRenderList()
                })
                return;
            }
        },


        showStateForCountry(countryID, countryName) {
            this.selectedCountryID = countryID;
            this.selectedCountryName=countryName;
            this.stateList = []
            this.loadStates(countryID);
        },
        showCityForState(stateID, stateName){
            this.selectedStateID = stateID;
            this.selectedStateName = stateName;
            this.cityList = [];
            this.loadCities(stateID);
        },

        onPickupCheckAllChange(type, checkboxID){
            let checkboxValue = document.querySelector(`#${checkboxID}`).checked;
            if (type=='country'){
                if (checkboxValue) {
                    this.countryList.forEach((country)=>{
                        if(this.selectedPickupCountries.indexOf(country.id)==-1) this.selectedPickupCountries.push(country.id)
                    })
                }else{
                    this.countryList.forEach((country)=>{
                        const index = this.selectedPickupCountries.indexOf(country.id);
                        if (index > -1) {
                            this.selectedPickupCountries.splice(index, 1);
                        }
                    })
                }
                this.reRenderList(type);
            }
            if (type=='state'){
                if (checkboxValue) {
                    this.stateList.forEach((state)=>{
                        if(this.selectedPickupStates.indexOf(state.id)==-1) this.selectedPickupStates.push(state.id)
                    })
                }else{
                    this.stateList.forEach((state)=>{
                        const index = this.selectedPickupStates.indexOf(state.id);
                        if (index > -1) {
                            this.selectedPickupStates.splice(index, 1);
                        }
                    })
                }
                this.reRenderList(type);
            }
            if (type=='city'){
                if (checkboxValue) {
                    this.cityList.forEach((city)=>{
                        if(this.selectedPickupCities.indexOf(city.id)==-1) this.selectedPickupCities.push(city.id)
                    })
                }else{
                    this.cityList.forEach((city)=>{
                        const index = this.selectedPickupCities.indexOf(city.id);
                        if (index > -1) {
                            this.selectedPickupCities.splice(index, 1);
                        }
                    })
                }
                this.reRenderList(type);
            }
        },

        onDeliveryCheckAllChange(type, checkboxID){
            let checkboxValue = document.querySelector(`#${checkboxID}`).checked;
            var selected = [];
            if (type=='country'){
                if (checkboxValue) {
                    this.countryList.forEach((country)=>{
                        if(this.selectedDeliveryCountries.indexOf(country.id)==-1) this.selectedDeliveryCountries.push(country.id)
                    })
                }else{
                    this.countryList.forEach((country)=>{
                        const index = this.selectedDeliveryCountries.indexOf(country.id);
                        if (index > -1) {
                            this.selectedDeliveryCountries.splice(index, 1);
                        }
                    })
                }
                this.reRenderList(type);
            }
            
            if (type=='state'){
                if (checkboxValue) {
                    this.stateList.forEach((state)=>{
                        if(this.selectedDeliveryStates.indexOf(state.id)==-1) this.selectedDeliveryStates.push(state.id)
                    })
                }else{
                    this.stateList.forEach((state)=>{
                        const index = this.selectedDeliveryStates.indexOf(state.id);
                        if (index > -1) {
                            this.selectedDeliveryStates.splice(index, 1);
                        }
                    })
                }
                this.reRenderList(type);
            }
            if (type=='city'){
                if (checkboxValue) {
                    this.cityList.forEach((city)=>{
                        if(this.selectedDeliveryCities.indexOf(city.id)==-1) this.selectedDeliveryCities.push(city.id)
                    })
                }else{
                    this.cityList.forEach((city)=>{
                        const index = this.selectedDeliveryCities.indexOf(city.id);
                        if (index > -1) {
                            this.selectedDeliveryCities.splice(index, 1);
                        }
                    })
                }
                this.reRenderList(type);
            }
        },

        onPickupChange(checkboxID, countryID=null, stateID=null, cityID=null){
            let checkboxValue = document.querySelector(`#${checkboxID}`).checked ? 1 : 0;

            if (countryID){
                if (checkboxValue){
                    this.selectedPickupCountries.push(countryID)
                }else{
                    const index = this.selectedPickupCountries.indexOf(countryID);
                    if (index > -1) {
                        this.selectedPickupCountries.splice(index, 1);
                    }
                }
                this.reRenderList('country');
            }
            if (stateID){
                if (checkboxValue){
                    this.selectedPickupStates.push(stateID)
                }else{
                    const index = this.selectedPickupStates.indexOf(stateID);
                    if (index > -1) {
                        this.selectedPickupStates.splice(index, 1);
                    }
                }
                this.reRenderList('state');
            }
            if (cityID){
                if (checkboxValue){
                    this.selectedPickupCities.push(cityID)
                }else{
                    const index = this.selectedPickupCities.indexOf(cityID);
                    if (index > -1) {
                        this.selectedPickupCities.splice(index, 1);
                    }
                }
                this.reRenderList('city');
            }
        },
        onDeliveryChange(checkboxID, countryID=null, stateID=null, cityID=null){
            let checkboxValue = document.querySelector(`#${checkboxID}`).checked ? 1 : 0;

            if (countryID){
                // const data = {
                //     "countryID": countryID,
                //     "value": checkboxValue,
                // }
                if (checkboxValue){
                    this.selectedDeliveryCountries.push(countryID)
                }else{
                    const index = this.selectedDeliveryCountries.indexOf(countryID);
                    if (index > -1) {
                        this.selectedDeliveryCountries.splice(index, 1);
                    }
                }
                this.reRenderList('country');
                // this.selectedDeliveryCountries.push(countryID)
                // this.reRenderCountryList();
                // repository.regions.setCountryDelivery(data)
            }
            if (stateID){
                if (checkboxValue){
                    this.selectedDeliveryStates.push(stateID)
                }else{
                    const index = this.selectedDeliveryStates.indexOf(stateID);
                    if (index > -1) {
                        this.selectedDeliveryStates.splice(index, 1);
                    }
                }
                this.reRenderList('state');
            }
            if (cityID){
                if (checkboxValue){
                    this.selectedDeliveryCities.push(cityID)
                }else{
                    const index = this.selectedDeliveryCities.indexOf(cityID);
                    if (index > -1) {
                        this.selectedDeliveryCities.splice(index, 1);
                    }
                }
                this.reRenderList('city');
            }
        },

        reRenderList(type) {
            if (!type){

                var checkAllPickupCountry = true;
                for (var country of this.countryList){
                    if (!document.querySelector(`#checkbox_pickup_${country.id}`).checked){
                        checkAllPickupCountry = false;
                        break;
                    } 
                    
                }
                document.querySelector(`#checkbox_country_pickup_all`).checked = checkAllPickupCountry;
                var checkAllDeliveryCountry = true;
                for (var country of this.countryList){
                    if (!document.querySelector(`#checkbox_delivery_${country.id}`).checked){
                        checkAllDeliveryCountry = false;
                        break;
                    } 
                    
                }
                document.querySelector(`#checkbox_country_delivery_all`).checked = checkAllDeliveryCountry;






                var checkAllPickupState = true;
                for (var state of this.stateList){
                    if (!document.querySelector(`#checkbox_pickup_${state.id}`).checked){
                        checkAllPickupState = false;
                        break;
                    } 
                    
                }
                document.querySelector(`#checkbox_state_pickup_all`).checked = checkAllPickupState;
                var checkAllDeliveryState = true;
                for (var state of this.stateList){
                    if (!document.querySelector(`#checkbox_delivery_${state.id}`).checked){
                        checkAllDeliveryState = false;
                        break;
                    } 
                    
                }
                document.querySelector(`#checkbox_state_delivery_all`).checked = checkAllDeliveryState;









                var checkAllPickupCity = true;
                for (var city of this.cityList){
                    if (!document.querySelector(`#checkbox_pickup_${city.id}`).checked){
                        checkAllPickupCity = false;
                        break;
                    } 
                    
                }
                document.querySelector(`#checkbox_city_pickup_all`).checked = checkAllPickupCity;
                var checkAllDeliveryCity = true;
                for (var city of this.cityList){
                    if (!document.querySelector(`#checkbox_delivery_${city.id}`).checked){
                        checkAllDeliveryCity = false;
                        break;
                    } 
                    
                }
                document.querySelector(`#checkbox_city_delivery_all`).checked = checkAllDeliveryCity;


                


                // if (document.querySelector(`#checkbox_country_pickup_all`)) {
                //     document.querySelector(`#checkbox_country_pickup_all`).checked = this.countryList.length == this.selectedPickupCountries.length;
                // }
                // if (document.querySelector(`#checkbox_country_delivery_all`)){
                //     document.querySelector(`#checkbox_country_delivery_all`).checked = this.countryList.length == this.selectedDeliveryCountries.length;
                // }
                // if (document.querySelector(`#checkbox_state_pickup_all`)){
                //     document.querySelector(`#checkbox_state_pickup_all`).checked = this.stateList.length == this.selectedPickupStates.length;
                // }
                // if (document.querySelector(`#checkbox_state_delivery_all`)){
                //     document.querySelector(`#checkbox_state_delivery_all`).checked = this.stateList.length == this.selectedDeliveryStates.length;
                // }
                // if (document.querySelector(`#checkbox_city_pickup_all`)){
                //     document.querySelector(`#checkbox_city_pickup_all`).checked = this.cityList.length == this.selectedPickupCities.length;
                // }
                // if (document.querySelector(`#checkbox_city_delivery_all`)){
                //     document.querySelector(`#checkbox_city_delivery_all`).checked = this.cityList.length == this.selectedDeliveryCities.length;
                // }
            }
            if (type=='country'){
                var checkAllPickupCountry = true;
                var checkAllDeliveryCountry = true;
                this.countryList.forEach((country)=>{

                    document.querySelector(`#checkbox_pickup_${country.id}`).checked = this.selectedPickupCountries.includes(country.id);
                    document.querySelector(`#checkbox_delivery_${country.id}`).checked = this.selectedDeliveryCountries.includes(country.id);

                    if (!document.querySelector(`#checkbox_pickup_${country.id}`).checked) checkAllPickupCountry = false;
                    if (!document.querySelector(`#checkbox_delivery_${country.id}`).checked) checkAllDeliveryCountry = false;
                })

                document.querySelector(`#checkbox_country_pickup_all`).checked = checkAllPickupCountry;
                document.querySelector(`#checkbox_country_delivery_all`).checked = checkAllDeliveryCountry;
                const pickupData = {
                    "pickupCountries":this.selectedPickupCountries,
                }
                this.debounce(function(){
                    repository.regions.setCountryPickup(pickupData)
                }, 2000)
                
                const deliveryData = {
                    "deliveryCountries":this.selectedDeliveryCountries,
                }
                this.debounce(function(){
                    repository.regions.setCountryDelivery(deliveryData)
                }, 2000)
                
            }
            if (type=='state'){
                var checkAllPickupState = true;
                var checkAllDeliveryState = true;
                this.stateList.forEach((state)=>{

                    document.querySelector(`#checkbox_pickup_${state.id}`).checked = this.selectedPickupStates.includes(state.id);
                    document.querySelector(`#checkbox_delivery_${state.id}`).checked = this.selectedDeliveryStates.includes(state.id);

                    if (!document.querySelector(`#checkbox_pickup_${state.id}`).checked) checkAllPickupState = false;
                    if (!document.querySelector(`#checkbox_delivery_${state.id}`).checked) checkAllDeliveryState = false;
                })

                document.querySelector(`#checkbox_state_pickup_all`).checked = checkAllPickupState;
                document.querySelector(`#checkbox_state_delivery_all`).checked = checkAllDeliveryState;
                const pickupData = {
                    "pickupStates":this.selectedPickupStates,
                    "country_id": this.selectedCountryID,
                }
                this.debounce(async function(){
                    repository.regions.setStatePickup(pickupData)
                }, 2000)
                
                const deliveryData = {
                    "deliveryStates":this.selectedDeliveryStates,
                    "country_id": this.selectedCountryID,
                }
                this.debounce(async function(){
                    const response = await repository.regions.setStateDelivery(deliveryData)
                }, 2000)
                
            }

            if (type=='city'){
                var checkAllPickupCity = true;
                var checkAllDeliveryCity = true;
                this.cityList.forEach((city)=>{

                    document.querySelector(`#checkbox_pickup_${city.id}`).checked = this.selectedPickupCities.includes(city.id);
                    document.querySelector(`#checkbox_delivery_${city.id}`).checked = this.selectedDeliveryCities.includes(city.id);

                    if (!document.querySelector(`#checkbox_pickup_${city.id}`).checked) checkAllPickupCity = false;
                    if (!document.querySelector(`#checkbox_delivery_${city.id}`).checked) checkAllDeliveryCity = false;
                })

                document.querySelector(`#checkbox_city_pickup_all`).checked = checkAllPickupCity;
                document.querySelector(`#checkbox_city_delivery_all`).checked = checkAllDeliveryCity;
                const pickupData = {
                    "pickupCities":this.selectedPickupCities,
                    "state_id": this.selectedStateID,
                }
                this.debounce(async function(){
                    repository.regions.setCityPickup(pickupData)
                }, 2000)
                
                const deliveryData = {
                    "deliveryCities":this.selectedDeliveryCities,
                    "state_id": this.selectedStateID,
                }
                this.debounce(async function(){
                    repository.regions.setCityDelivery(deliveryData)
                }, 2000)
            }
        },


        async deleteCountry(countryID) {
            if (confirm('Are you sure you want to delete this country? All states and cities under this country will be deleted and this action cannot be reversed.')){
                await repository.regions.deleteCountry(countryID);
                this.loadCountries();
            }
        },
        async deleteState(stateID) {
            if (confirm('Are you sure you want to delete this state? All cities under this state will be deleted and this action cannot be reversed.')){
                await repository.regions.deleteState(stateID);
                this.loadStates(this.selectedCountryID);
            }
        },
        async deleteCity(cityID) {
            if (confirm('Are you sure you want to delete this city? This action cannot be reversed.')){
                await repository.regions.deleteCity(cityID);
                this.loadCities(this.selectedStateID);
            }
        },




        debounce(func, delay) {
            let debounceTimer
            const context = this
            const args = arguments
            clearTimeout(debounceTimer)
            debounceTimer = setTimeout(() => func.apply(context, args), delay)
        },

        // togglePickupCountries() {
        //     const toggleState = document.querySelector(`#checkbox_country_pickup_all`).checked;
        //     this.countryList.forEach((country)=>{
        //         let pickupCheckbox = document.querySelector(`#checkbox_pickup_${country.id}`)
        //         pickupCheckbox.checked = toggleState;
        //     });
        // },
        // toggleDeliveryCountries() {
        //     const toggleState = document.querySelector(`#checkbox_country_delivery_all`).checked;
        //     this.countryList.forEach((country)=>{
        //         let deliveryCheckbox = document.querySelector(`#checkbox_delivery_${country.id}`)
        //         deliveryCheckbox.checked = toggleState;
        //     });
        // },
        // togglePickupStates() {
        //     const toggleState = document.querySelector(`#checkbox_state_pickup_all`).checked;
        //     this.stateList.forEach((state)=>{
        //         let pickupCheckbox = document.querySelector(`#checkbox_pickup_${state.id}`)
        //         pickupCheckbox.checked = toggleState;
        //     });
        // },
        // toggleDeliveryStates() {
        //     const toggleState = document.querySelector(`#checkbox_state_delivery_all`).checked;
        //     this.stateList.forEach((state)=>{
        //         let deliveryCheckbox = document.querySelector(`#checkbox_delivery_${state.id}`)
        //         deliveryCheckbox.checked = toggleState;
        //     });
        // },
        // togglePickupCities() {
        //     const toggleState = document.querySelector(`#checkbox_city_pickup_all`).checked;
        //     this.cityList.forEach((city)=>{
        //         let pickupCheckbox = document.querySelector(`#checkbox_pickup_${city.id}`)
        //         pickupCheckbox.checked = toggleState;
        //     });
        // },
        // toggleDeliveryCities() {
        //     const toggleState = document.querySelector(`#checkbox_city_delivery_all`).checked;
        //     this.cityList.forEach((city)=>{
        //         let deliveryCheckbox = document.querySelector(`#checkbox_delivery_${city.id}`)
        //         deliveryCheckbox.checked = toggleState;
        //     });
        // },

        

    }
}
</script>

<style scoped>
.entry{
    cursor: pointer;
    padding-top: 1.5rem;
}
.entry:hover {
    background-color: #eeeeee;
}
[type=checkbox]+label {
    padding-left: 1.8rem;
}
hr{
    margin: 0;
}
</style>


