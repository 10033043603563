<template>
    <div id="add-city" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel2">Add City</h4>
                    <button ref="add_city_close_button" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form class="form-horizontal form-element">
                        <div class="col-md-12 mb-20">
                            <p class="text-danger" v-if="error">{{errorMessage}}</p>
                        </div>
                        <div class="col-md-12 mb-20">
                            <div class="form-group">
                                <input v-model="cityName" type="text" class="form-control" placeholder="City Name">
                                <p class="error" v-if="cityNameError">{{cityNameErrorMessage}}</p>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click="saveNewCity">Save<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import { repository } from '../../presenter'
export default {
  name: 'NewCity',
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', '/assets/styles/vendor_components/sweetalert/sweetalert.min.js')
    document.head.appendChild(sweetAlert)
  },
  props: {
    stateID: {
      type: Number,
      required: false,
    }
  },
  data () {
    return {
      
      cityName: '',
      cityNameError: '',
      cityNameErrorMessage: '',
      loading: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: ''
    }
  },
  methods: {
    async saveNewCity () {
      if (this.loading) return
      this.resetData()
      const newCityDataValidation = this.validateNewCityData()
      if (!newCityDataValidation.success) return
      this.loading = true
      const data = {
        name: this.cityName,
        stateID: this.stateID,
      }
      const response = await repository.regions.createNewCity(data)
      this.loading = false
      console.log('response is ', response)
      if (response.success) {
        this.resetData()
        this.showSuccessMessage('City Created');
        return
      }
      this.showErrorMessage(response.data)
    },

    validateNewCityData () {
      const returnValue = new Object()
      if (!this.cityName) {
        this.cityNameError = true
        this.cityNameMessage = 'Enter a valid name'
        returnValue.success = false
        return returnValue
      }
      returnValue.success = true
      return returnValue
    },

    showSuccessMessage (message) {
      swal(message, "", "success")
      this.$refs.add_city_close_button.click();
    },
    showErrorMessage (message) {
      this.error = true
      this.errorMessage = message
    },

    resetData () {
      this.cityNameError = false
      this.success = false
      this.successMessage = ''
      this.error = false
      this.errorMessage = ''
    }
  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>

